import React from 'react';

import CustomButton from 'components/partials/custom-button/custom-button';

const buttonClasses =
  'w-full h-11 transition-all outline-none px-6 py-2 text-2xl text-white bg-gradient-to-t from-blue-02 to-blue-05 rounded-lg leading-tight shadow-default hover:from-blue-02 hover:to-blue-02 active:bg-gradient-to-t active:from-blue-05 active:to-blue-02 !font-normal';

const SubmitButton = ({ disabled, children }) => (
  <CustomButton type="submit" customClass={buttonClasses} disabled={disabled}>
    {children}
  </CustomButton>
);

export default SubmitButton;
