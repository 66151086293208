import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router';

import { Formik, Form } from 'formik';
import queryString from 'query-string';

import EnrollLayout from 'containers/enroll/enroll-layout';

import FormField from 'components/shared/form/form-field';
import SubmitButton from 'components/shared/form/submit-button';
import FlashNotification from 'components/partials/flash-notification/flash-notification';

import api from 'api';

import Notification from 'components/partials/notification/notification.helper';

const ResetPassword = ({ match, location, history }) => {
  const params = queryString.parse(location.search);

  const key = params.key;

  const validation = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain an uppercase letter')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[#?!@$%^&*-]/, 'Password must contain a symbol'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Must match password')
      .required('Password confirmation is required'),
  });

  return (
    <EnrollLayout>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          api
            .resetPassword({
              key: key,
              newPassword: values.password,
            })
            .then((resp) => {
              Notification.flash(
                'Success!',
                'success',
                `Your password has been changed. Please login to continue.`
              );

              // redirect to login after activation
              history.push('/login');
            })
            .catch((error) => {
              Notification.flash(
                'Activation error',
                'error',
                `There was an error when changing your password. Please try again or contact support. (${error.status})`
              );
            });

          setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <FlashNotification />

            <p className="text-4xl text-bold text-indigo-02 font-family">
              Choose a password
            </p>
            <FormField
              name="password"
              label="Password"
              type="password"
              help="At least 8 characters, with 1 number and 1 special character"
            />
            <FormField
              name="passwordConfirmation"
              type="password"
              label="Confirm password"
            />

            <div className="flex justify-end">
              <SubmitButton>Continue</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </EnrollLayout>
  );
};

export default withRouter(ResetPassword);
