import React, { forwardRef } from 'react';
import classNames from 'classnames';

import Icons from 'assets/icons';

const CustomTextInput = forwardRef(
  (
    {
      customClass = '',
      label = '',
      labelFor = '',
      errorsNames = {},
      helperText = '',
      domainText = '',
      type = 'text',
      placeholder = 'Type...',
      disabled,
      disabledClassName = 'bg-gray-06',
      inputCustomClass = '',
      inputWrapperCustomClass = '',
      labelInTop = false,
      required,
      maxLength,
      ...rest
    },
    ref
  ) => {
    const isValid = Object.keys(errorsNames).length === 0;

    const inputStyles = classNames(
      'border border-solid rounded-md p-2.5 focus:outline-none',
      {
        'border-red-01': !isValid,
        'border-gray-05': isValid,
      },

      inputCustomClass
    );

    const labelStyles = classNames('font-family font-semibold', customClass, {
      'w-32 mr-14 mb-5': !labelInTop,
      'flex mb-[0.25rem] mt-3': labelInTop,
    });

    const wrapperClass = classNames(inputWrapperCustomClass, {
      [disabledClassName]: disabled,
    });

    return (
      <div className="flex flex-col w-full">
        <div
          className={classNames('flex', {
            'flex-col items-start': labelInTop,
            'items-center': !labelInTop,
          })}
        >
          {label && (
            <label htmlFor={labelFor} className={labelStyles}>
              {label}
              {required ? (
                <Icons.RequiredIndicator customClass="w-[7px] h-[7px] ml-1 mt-1" />
              ) : null}
            </label>
          )}
          <div className={`flex flex-col w-full ${wrapperClass}`}>
            <input
              id={labelFor}
              className={inputStyles}
              ref={ref}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength}
              {...rest}
            />
            {helperText && !disabled && isValid && (
              <span id="custom-input" className="font-sm pt-[0.25rem] bg-white">
                {`${helperText} ${domainText}`}
              </span>
            )}
            {!isValid && !disabled && (
              <span className="font-sm pt-[0.25rem] text-red-00 bg-white">
                {errorsNames.message}
              </span>
            )}
            {!disabled && maxLength && (
              <span className="mt-1 text-sm">
                {rest.value?.length} of {maxLength} characters used
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomTextInput;
