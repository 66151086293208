import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import api from 'api';

import Tabs from 'components/partials/tabs/tabs';
import toast from 'components/partials/toast/toast';
import {
  GoalFormProvider,
  useGoalForm,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/context/context';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

import GoalHeader from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-header/goal-header';
import GoalForm from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/goal-form';

import {
  getPathToGoal,
  getUrlParams,
  prepareGoalPayload,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goals-manager.helper';
import { getActionMenuItems } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal.helpers';

const getTabItems = () => [
  { label: 'Details', value: 'details', isDisabled: false },
];

const Goal = ({
  data,
  goalsLength,
  isEditMode,
  isCreation,
  setIsEditMode,
  getGoals,
  selectedGoalId,
  setLoading,
  loading,
}) => (
  <GoalFormProvider
    defaultValues={{
      value: '',
      relativeToLastYear: 'Increase',
      details: [],
      name: '',
      description: '',
    }}
    loading={loading}
  >
    <GoalInner
      data={data}
      goalsLength={goalsLength}
      isEditMode={isEditMode}
      isCreation={isCreation}
      setIsEditMode={setIsEditMode}
      getGoals={getGoals}
      selectedGoalId={selectedGoalId}
      setLoading={setLoading}
    />
  </GoalFormProvider>
);

const GoalInner = ({
  data,
  goalsLength,
  isEditMode,
  setIsEditMode,
  getGoals,
  selectedGoalId,
  isCreation,
  setLoading,
}) => {
  const [goal, setGoal] = useState(data);
  const [activeTab, setActiveTab] = useState('details');

  const params = useParams();
  const history = useHistory();

  const { type, category, enteringClassYear, clientId } = getUrlParams(params);
  const { getValues, handleSubmit, reset } = useGoalForm();
  const user = useCurrentUser();
  const userId = user?.data?.oktaUserId;

  useEffect(() => {
    if (isCreation) {
      setGoal({});
      reset();
    }
  }, [isCreation, reset]);

  useEffect(() => {
    setGoal(data);
  }, [data]);

  useEffect(() => {
    setActiveTab('details');
  }, [selectedGoalId]);

  const onCancel = useCallback(
    (isNew) => {
      if (isEditMode) {
        setIsEditMode(false);

        return;
      }

      if (isNew && !goalsLength) {
        history.push(`/admin/clients/${clientId}/goals/${enteringClassYear}`);
      } else {
        const goalPath = getPathToGoal(window.location.pathname);

        history.push(goalPath);
      }
    },
    [clientId, goalsLength, history, isEditMode, setIsEditMode]
  );

  const onSubmit = useCallback(async () => {
    const formData = getValues();

    const payload = prepareGoalPayload({
      userId,
      clientId,
      data: { ...data, type, category, enteringClassYear },
      formData,
    });

    try {
      setLoading(true);
      if (goal?.id) {
        await api.modifyGoal(payload);

        if (isEditMode) {
          setIsEditMode(false);
        }
        await getGoals();
      } else {
        await api.createGoal(payload);
        const pathToGoal = getPathToGoal(window.location.pathname);

        history.push(pathToGoal);
      }
      toast.success({
        title: `The goal was successfully ${goal?.id ? 'updated' : 'created'}`,
      });
    } catch (e) {
      toast.error({ title: 'Something went wrong' });
      console.error(
        `
        Error when ${goal?.id ? 'editing' : 'creating'} a goal`,
        e
      );
      setLoading(false);
    }
  }, [
    category,
    clientId,
    data,
    enteringClassYear,
    getGoals,
    getValues,
    goal?.id,
    history,
    isEditMode,
    setIsEditMode,
    type,
    userId,
  ]);

  const handleFormSubmit = handleSubmit(onSubmit);

  const handleDelete = useCallback(
    async (id) => {
      setLoading(true);
      try {
        await api.deleteGoal({ userId, clientId, id });
        const goalPath = getPathToGoal(window.location.pathname);

        history.push(goalPath);
        toast.success({
          title: `The goal was successfully removed`,
        });
      } catch (e) {
        toast.error({ title: 'Something went wrong' });

        console.error('Error when deleting a goal', e);
        setLoading(false);
      }
    },
    [clientId, history, userId]
  );

  const renderTab = (data) => {
    switch (activeTab) {
      case 'details':
        const params = {
          type: data.type,
          category: data.category,
          isCreation,
          ...(data.id ? { isEditing: isEditMode, data } : {}),
        };

        return <GoalForm {...params} />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full pt-4 pl-4">
      <Tabs
        items={getTabItems({ isDisabled: !goal?.id || isEditMode })} // getTabItems will have 2 more items and we'll use isDisabled prop
        activeTab={activeTab}
        onSelect={setActiveTab}
        customClass="mb-3"
      />
      <GoalHeader
        actionMenuItems={getActionMenuItems({
          handleDelete,
          goalId: goal?.id,
        })}
        isEditMode={isEditMode}
        onEditClick={() => setIsEditMode(true)}
        customClass="mb-5"
        isNew={!goal?.id}
        onCancel={onCancel}
        onSubmit={handleFormSubmit}
      />
      {renderTab({ ...goal, type, category, enteringClassYear })}
    </div>
  );
};

export default React.memo(Goal);
