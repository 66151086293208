import React from 'react';
import classNames from 'classnames';
import { Field, ErrorMessage } from 'formik';

import CustomTextInput from 'components/partials/custom-text-input/custom-text-input';

import Icons from 'assets/icons';

const FieldWithValidation = ({ name, element, type, value, locked }) => (
  <>
    <div className="flex justify-between items-sencter">
      {locked && <Icons.Lock customClass="h-4 w-4" />}
    </div>
    <Field name={name} type={type} value={value}>
      {element}
    </Field>
  </>
);

export const FormFieldWrapper = (props) => <div className="mb-6" {...props} />;

export const FormFieldError = ({ name }) => (
  <ErrorMessage name={name}>
    {(message) => <p className="text-red-01">{message}</p>}
  </ErrorMessage>
);

export const FormFieldInput = ({
  label,
  name,
  component,
  helperText,
  locked,
  ...props
}) => {
  const FormElement = ({ field, form: { touched, errors } }) => {
    const hasError = touched[field.name] && errors[field.name];

    const styles = classNames({
      '!border-red-01 !text-red-01': hasError,
      'focus:border-dark-00': !hasError,
    });

    const Component = React.createElement(CustomTextInput, {
      onChange: field.onChange,
      disabled: locked,
      inputCustomClass: styles,
      helperText,
      label: label,
      labelInTop: true,
      inputWrapperCustomClass: 'mb-0',

      placeholder: '',
      ...field,
    });

    return Component;
  };

  return (
    <FieldWithValidation
      label={label}
      name={name}
      element={FormElement}
      type={props.type}
      value={props.value}
      locked={locked}
    />
  );
};

const FormField = ({ label, name, component, help, locked = false }) => (
  <FormFieldWrapper data-form-field={name}>
    <FormFieldInput
      label={label}
      name={name}
      component={component}
      helperText={help}
      locked={locked}
      aria-label={name}
    />
    <FormFieldError name={name} />
  </FormFieldWrapper>
);

export default FormField;
