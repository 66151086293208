import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';

import uniqid from 'uniqid';

import Report from 'containers/user/reports/components/report/report';
import FlashNotification from 'components/partials/flash-notification/flash-notification';

import { useReports } from 'state/reports/reports.thunk';
import { history } from 'state/history';

import { checkPage } from 'helpers/check-page';

import { REPORT_TYPES } from 'constants/reports';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const Reports = ({ isPopover = false }) => {
  const reports = useReports();
  const [currentReport, setCurrentReport] = useState('');

  useEffect(() => {
    reports?.loadIfMissing();
  }, [reports]);

  useEffect(() => {
    if (checkPage('report')) {
      const currentReportId = window.location.pathname.match(/(\d+)/)?.[0];

      setCurrentReport(currentReportId);
    }
  }, []);

  const navigateToReport = (reportId) => {
    history.push(`/reports/${reportId}/report-details`);
  };

  return (
    <ResponsiveWrapper>
      <div className="font-black text-[28px] text-indigo-02 font-family my-4">
        All Reports
      </div>
      <FlashNotification customClass="mt-6" />
      <div className="grid grid-cols-4 grid-rows-1 gap-5">
        {reports?.data?.map((report) =>
          report.powerBIReportType === REPORT_TYPES.DASHBOARD_1 ? null : (
            <Report
              key={uniqid()}
              navigateToReport={() => navigateToReport(report.id)}
              report={report}
              isActive={isPopover && String(report.id) === currentReport}
            />
          )
        )}
      </div>
    </ResponsiveWrapper>
  );
};

export default withRouter(Reports);
