import React, { useContext, useState, useEffect } from 'react';
import api from 'api';
import { FeatureFlagsContext } from 'app';

import HeroLearn from 'containers/user/learn/components/hero-learn/hero-learn-partial';
import CategoriesContent from 'containers/user/learn/components/categories-content/categories-content';
import Loader from 'components/partials/loader/loader';
import NothingFound from 'containers/user/learn/components/empty-row/empty-row';
import CoursesCards from 'containers/user/learn/components/courses-cards/courses-cards';
import useFetchCourses from 'containers/user/learn/containers/content/cources/use-fetch-courses';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
const url = `/courses/trainingHub/index.html`;

const Learn = () => {
  const { featureFlags } = useContext(FeatureFlagsContext);
  const [loading, setLoading] = useState(false);
  const user = useCurrentUser();

  const fetchCoursesResult = useFetchCourses(user?.data?.oktaUserId);
  const courses = featureFlags.courses ? fetchCoursesResult.courses : [];

  const [guides, setGuides] = useState([]);
  const [articles, setArticles] = useState([]);
  const [webinars, setWebinars] = useState([]);
  const [downloads, setDownloads] = useState([]);

  const contentTypes = {
    guides: {
      apiMethod: api.getGuides,
      setState: setGuides,
      path: 'guides',
      extraAttributes: ['coverDescription'],
    },
    articles: {
      apiMethod: api.getArticles,
      setState: setArticles,
      path: 'articles',
    },
    webinars: {
      apiMethod: api.getWebinars,
      setState: setWebinars,
      path: 'webinars',
      extraAttributes: ['coverDescription'],
    },
    downloads: {
      apiMethod: api.getDownloads,
      setState: setDownloads,
      path: 'downloads',
      extraAttributes: ['coverDescription'],
    },
  };

  useEffect(() => {
    const fetchData = async ({
      apiMethod,
      setState,
      path,
      extraAttributes = [],
    }) => {
      try {
        const res = await apiMethod(user?.data?.oktaUserId);
        const data = res?.data?.data?.map((el) => {
          let baseData = {
            name: el.attributes?.name,
            route: `/learn/content/${path}/${el.id}`,
            coverImage: el.attributes.coverImage?.data?.attributes?.url,
          };

          extraAttributes.forEach((attr) => {
            baseData[attr] = el.attributes?.[attr];
          });

          return baseData;
        });

        setState(data.slice(0, 3));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    setLoading(true);

    const fetchPromises = Object.entries(featureFlags).reduce(
      (acc, [key, isEnabled]) => {
        if (isEnabled && contentTypes[key]) {
          acc.push(fetchData(contentTypes[key]));
        }

        return acc;
      },
      []
    );

    Promise.all(fetchPromises).finally(() => setLoading(false));
  }, [featureFlags, user?.data?.oktaUserId]);

  const isAllEmpty = [guides, articles, webinars, downloads, courses].every(
    (array) => array.length === 0
  );

  return featureFlags.isLearnSectionPresented ? (
    <div className="w-full">
      <Loader loading={loading} />
      <HeroLearn />
      <div className="flex flex-col items-center">
        {isAllEmpty ? (
          <NothingFound
            customClass="mt-10"
            customTitle="There is no data yet"
          />
        ) : (
          <>
            {!!articles.length && (
              <CategoriesContent
                title="Articles"
                subtitle="Expert Insights on Trending Topics"
                items={articles}
                categoryLink="/learn/content/articles"
              />
            )}
            {!!courses.length && (
              <CoursesCards
                items={courses.slice(0, 2)} // show only 2 courses
                userId={user?.data?.oktaUserId}
                title="Available courses"
                subtitle="Learn How To Make the Most of MARKETview"
                showSeeAllButton
              />
            )}
            {!!downloads.length && (
              <CategoriesContent
                title="Downloads"
                subtitle="View Infographics, Whitepapers, Case Studies, and More"
                items={downloads}
                categoryLink="/learn/content/downloads"
              />
            )}
            {!!webinars.length && (
              <CategoriesContent
                title="Webinars"
                subtitle="Watch Recordings From Recent Webinars"
                items={webinars}
                categoryLink="/learn/content/webinars"
              />
            )}
            {!!guides.length && (
              <CategoriesContent
                title="Guides"
                subtitle="Browse Tutorials and Tips From MARKETview Partners"
                items={guides}
                categoryLink="/learn/content/guides"
              />
            )}
          </>
        )}
      </div>
    </div>
  ) : (
    <iframe
      src={url}
      className="w-full md:flex-1"
      title="MARKETview Learn Hub"
      style={{
        border: '0.25rem',
        height: '100vh',
      }}
    ></iframe>
  );
};

export default Learn;
