import { withAuth } from '@okta/okta-react';
import Icons from 'assets/icons';
import enrollImage from 'assets/images/onboarding/onboarding.png';

const EnrollLayout = ({ children }) => (
  <div className="flex flex-col md:flex-row min-h-screen">
    <div
      className="flex items-center justify-center w-full md:w-1/2 min-h-[25vh] bg-cover"
      style={{ backgroundImage: `url(${enrollImage})` }}
    >
      <div className="text-white flex flex-col items-center">
        <p className="block text-4xl font-bold leading-none mb-6">Welcome to</p>

        <Icons.FullLogo customClass="w-[260px] h-[53px]" />
      </div>
    </div>
    <div className="w-full md:w-1/2 p-8 md:p-32 flex items-start md:items-center min-h-[75vh]">
      <div className="w-full">{children}</div>
    </div>
  </div>
);

export default withAuth(EnrollLayout);
