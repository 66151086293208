import React from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router';

import { Formik, Form } from 'formik';

import LoginLayout from 'containers/login/login-layout';

import Divider from 'components/shared/others/divider/divider';
import FormField from 'components/shared/form/form-field';
import SubmitButton from 'components/shared/form/submit-button';
import FlashNotification from 'components/partials/flash-notification/flash-notification';

import api from 'api';

import Notification from 'components/partials/notification/notification.helper';

const RequestPassword = ({ history }) => {
  const validation = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email address')
      .required('Email address is required'),
  });

  return (
    <LoginLayout>
      <Divider customClass="mt-56 mb-20" />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          api
            .requestPasswordReset(values.email)
            .then((resp) => {
              Notification.flash(
                'Sent password reset email',
                'success',
                'Check your email for a password reset link.'
              );

              // redirect to login after activation
              history.push('/login');
            })
            .catch((error) => {
              Notification.flash(
                'Error sending password reset email',
                'error',
                (error && error.errorSummary) ||
                  (error.data && error.data.detail) ||
                  ''
              );
            });

          setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <FlashNotification />
            <p className="text-4xl font-bold font-family text-indigo-02">
              Reset Password
            </p>
            <FormField name="email" label="Email or Username" type="email" />

            <div className="flex justify-end">
              <SubmitButton>Continue</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </LoginLayout>
  );
};

export default withRouter(RequestPassword);
