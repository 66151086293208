import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import { getApiInfo } from 'api/api.config';
import { env } from 'env';

import FlashNotification from 'components/partials/flash-notification/flash-notification';

import loginImage from 'assets/images/login.svg';
import oldLogo from 'assets/images/old-marketview-logo.svg';

const LoginLayout = ({ auth, children }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [commitInfo, setCommitInfo] = useState('');

  const appEnv = env.REACT_APP_ENV;

  useEffect(() => {
    if (appEnv === 'dev' || appEnv === 'qa' || appEnv === 'uat') {
      getApiInfo()
        .then((resp) => {
          const data = resp.data;

          if (data.git.commit && data.build) {
            const infoText = `${data.git.commit.id?.abbrev} built on ${data.build.time}`;

            setCommitInfo(infoText);
          }
        })
        .catch((err) => {
          console.log('Api Info Error', err);
        });
    }
  }, []);

  useEffect(() => {
    checkAuthentication();
  });

  const checkAuthentication = async () => {
    const isAuthenticated = await auth?.isAuthenticated();

    if (isAuthenticated !== authenticated) {
      setAuthenticated(isAuthenticated);
    }
  };

  return authenticated ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <div>
      <div className="min-h-screen flex justify-center">
        <div className="relative w-full sm:w-1/2 px-8 sm:px-8 md:px-8 lg:px-32 py-4 sm:py-4 md:py-4 lg:py-16 flex flex-col justify-center">
          <FlashNotification customClass="mb-10" />
          <div className="flex justify-center">
            <img src={oldLogo} alt="MARKETview logo" />
          </div>
          {children}
          {(appEnv === 'dev' || appEnv === 'qa' || appEnv === 'uat') && (
            <span className="absolute bottom-8 font-sm">{commitInfo}</span>
          )}
        </div>
        <div
          className="items-center hidden sm:flex w-1/2 p-8"
          style={{ background: 'linear-gradient(#45B8E6, #007AAB)' }}
        >
          <img className="mx-auto" src={loginImage} alt="Login image" />
        </div>
      </div>
    </div>
  );
};

export default withAuth(LoginLayout);
