import React, { forwardRef } from 'react';

const CustomButton = forwardRef(
  (
    {
      customClass = '',
      iconBeforeCustomClass = '',
      iconAfterCustomClass = '',
      variant,
      size = 'md',
      iconBefore,
      iconAfter,
      onClick,
      disabled,
      children,
      type,
    },
    ref
  ) => {
    const sizeClass = {
      xs: 'font-sm px-2 py-1',
      sm: 'font-sm px-3 py-2 h-8',
      md: 'font-regular p-4 h-11',
      lg: 'font-large py-3 px-5 h-12',
      smSquare: 'font-sm px-2 py-2 h-8',
    };
    const variatClass = {
      primary:
        'text-indigo-02 bg-yellow-03 enabled:hover:bg-yellow-04 focus:bg-yellow-04 disabled:opacity-50',
      secondary:
        'border border-blue-04 text-indigo-02 enabled:hover:bg-gray-00 focus:border-indigo-02 disabled:opacity-50',
      secondaryWithoutBorder:
        'text-indigo-02 enabled:hover:bg-gray-00 focus:border-indigo-02 disabled:opacity-50',
      text: 'border-none text-blue-03 hover:text-indigo-02 disabled:text-gray-05',
      inverse:
        'bg-indigo-02 border border-white text-white hover:bg-blue-04 focus:bg-white focus:text-indigo-02 focus:border-indigo-02',
      disabled: 'bg-gray-06',
    };

    const defaultClass =
      'flex justify-center font-semibold items-center rounded-md';
    const btnClassName = `${defaultClass} ${variatClass[variant]} ${sizeClass[size]} ${customClass}`;

    return (
      <button
        className={btnClassName}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        type={type}
      >
        {!!iconBefore && (
          <span className={`pr-1 pt-px ${iconBeforeCustomClass}`}>
            {iconBefore}
          </span>
        )}
        {children}
        {!!iconAfter && (
          <span className={`pr-1 pt-px ${iconAfterCustomClass}`}>
            {iconAfter}
          </span>
        )}
      </button>
    );
  }
);

export default CustomButton;
