import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import { withAuth } from '@okta/okta-react';

import config from 'utils/okta/okta.config.js';

import 'containers/login/okta-widget/okta-theme.css';

const OktaSignInWidget = () => {
  const widget = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    const el = ReactDOM.findDOMNode(containerRef.current);

    widget.current = new OktaSignIn({
      baseUrl: config.url,
      clientId: config.clientId,
      redirectUri: config.redirectUri,
      authParams: {
        display: 'page',
        issuer: config.issuer,
        // responseType: ["id_token", "token"],
        // Added to match https://github.com/okta/samples-js-react/blob/master/custom-login/src/Login.jsx
        responseType: ['code'],
        pkce: true,
        scopes: ['openid', 'profile', 'email'],
      },
      features: {
        autoPush: true,
        rememberMe: true,
        registration: false,
        hideSignOutLinkInMFA: true,
        windowsVerify: true,
      },
      helpLinks: {
        forgotPassword: `${window.location.origin}/account/request-password-reset`,
      },
      brandName: 'MARKETview',
      logo: null,
      i18n: {
        //Overrides default text when using English. Override other languages by adding additional sections.
        en: {
          'primaryauth.title': 'Login', // Changes the sign in text, adding in text for a11y but hiding with css to match mocks
          'primaryauth.submit': 'Sign In', // Changes the sign in button
          autoPush: 'Send push automatically next time',
          'enroll.choices.description.generic':
            'MARKETview requires multifactor authentication to add an additional layer of security when signing in to your account.',
          'enroll.totp.setupApp':
            'Launch the {0} application on your mobile device and select Add an account.',
          'primaryauth.username.placeholder': 'Email Address',

          // More e.g. [primaryauth.username.placeholder,  primaryauth.password.placeholder, needhelp, etc.].
          // Full list here: https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/dist/properties/login.properties
        },
      },
    });

    function successCallback(res) {}

    function errorCallback(err) {
      console.log('WIDGET ERROR', err);
    }

    widget.current.renderEl({ el }, successCallback, errorCallback);

    return () => {
      widget.current.remove();
    };
  }, []);

  return <div ref={containerRef} />;
};

export default withAuth(OktaSignInWidget);
