import React from 'react';

import ActionMenu from 'components/partials/action-menu/action-menu';
import Heading from 'components/partials/heading/heading';
import classNames from 'classnames';
import CustomButton from 'components/partials/custom-button/custom-button';

const getHeaderTitle = (isNew, isEditMode) => {
  const goalStatuses = {
    new: 'New Goal',
    edit: 'Edit Goal',
    read: 'Goal Details',
  };

  return (
    (isNew && goalStatuses.new) ||
    (isEditMode && goalStatuses.edit) ||
    goalStatuses.read
  );
};

const GoalHeader = ({
  actionMenuItems,
  onEditClick,
  customClass,
  isNew,
  isEditMode,
  onCancel,
  onSubmit,
}) => (
  <div
    className={classNames(
      'flex justify-between items-center flex-wrap',
      customClass
    )}
  >
    <Heading variant="h3" customClass="capitalize font-bold">
      {getHeaderTitle(isNew, isEditMode)}
    </Heading>

    {isNew || isEditMode ? (
      <div className="flex items-center gap-2">
        <CustomButton
          variant="secondaryWithoutBorder"
          onClick={() => onCancel(isNew)}
        >
          Cancel
        </CustomButton>
        <CustomButton variant="primary" onClick={onSubmit}>
          Save changes
        </CustomButton>
      </div>
    ) : (
      <ActionMenu
        buttonName="Edit goal"
        options={actionMenuItems}
        onEditClick={onEditClick}
      />
    )}
  </div>
);

export default GoalHeader;
