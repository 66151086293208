import React from 'react';
import Tile from 'components/shared/others/tile/tile';
import classNames from 'classnames';

const Report = ({ report, isActive, navigateToReport }) => (
  <Tile
    customClass={classNames(
      'hover:text-indigo-02 hover:!border-indigo-02 hover:cursor-pointer',
      {
        'border-indigo-02 text-indigo-02': isActive,
        'border-gray-05 text-gray-03': !isActive,
      }
    )}
    onClick={navigateToReport}
  >
    <div className="flex flex-col">
      <div className="flex">
        {isActive && (
          <div
            className="
            w-2 h-2
            bg-yellow-03
            rounded-full
            mt-2
            mr-2"
          ></div>
        )}
        <p className="font-bold text-base">{report.title}</p>
      </div>
      <p className="text-base">{report.description}</p>
    </div>
  </Tile>
);

export default Report;
