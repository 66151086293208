import React from 'react';

import Reports from 'containers/user/reports/reports';

const ReportsPopover = () => (
  <div className="absolute top-14 left-0 right-0 mx-auto z-[700] p-8 pt-0 bg-white max-w-[1440px] min-w-[880px] w-[90%] h-[365px] overflow-auto">
    <Reports isPopover={true} />
  </div>
);

export default ReportsPopover;
