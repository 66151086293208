import api from 'api/api.config';

export const getAllGoals = ({ userId, clientId, year }) =>
  api.get(
    `/${userId}/clients/${clientId}/goals?enteringClassYear.equals=${year}&size=1000&sort=createdDate,desc`
  );

export const getGoals = ({ userId, clientId, category, type, year }) =>
  api.get(
    `/${userId}/clients/${clientId}/goals?type.contains=${type}&category.contains=${category}&enteringClassYear.equals=${year}&sort=createdDate,desc`
  );

export const getGoal = ({ userId, clientId, id }) =>
  api.get(`/${userId}/clients/${clientId}/goals/${id}`);

export const deleteGoal = ({ userId, clientId, id }) =>
  api.delete(`/${userId}/clients/${clientId}/goals/${id}`);

export const createGoal = ({ userId, clientId, goalData }) =>
  api.post(`/${userId}/clients/${clientId}/goals`, JSON.stringify(goalData));

export const modifyGoal = ({ userId, clientId, id, goalData }) =>
  api.put(
    `/${userId}/clients/${clientId}/goals/${id}`,
    JSON.stringify(goalData)
  );
