import { useDispatch, useSelector } from 'react-redux';

import { changePasswordAction } from 'state/current-user/actions/password-change.action';
import { currentUserInfoAction } from 'state/current-user/actions/current-user-info.action';
import { actions as currentUserActions } from 'state/current-user/current-user.slice';

import { useOktaSession } from 'state/okta-session/okta-session.thunk';
import { useClients } from 'state/clients/clients.thunk';

import api from 'api';

import { useOkta } from 'utils/okta/use-okta';

import { useGenericCollection } from 'helpers/state/use-generic-collection';
import { clearOktaSessionInfo } from 'helpers/okta-session';

import Notification from 'components/partials/notification/notification.helper';

const { setNewFilesCountSucceeded } = currentUserActions;

export const useCurrentUser = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.currentUser);

  const okta = useOkta();
  const oktaSession = useOktaSession();
  const clients = useClients();
  const collectionMethods = useGenericCollection('user');

  return {
    getInfo: () => {
      // Make sure the sessions user is active before loading user info to prevent additional
      // request on failed login which was causing two "Your school's subscription is locked" to
      // appear
      //
      oktaSession
        .getCurrentSessionInfo()
        .then((session) => {
          if (session && session.status === 'ACTIVE') {
            dispatch(currentUserInfoAction(clients));
          } else {
            Notification.flash(
              'Session Expired',
              'info',
              'Your session has expired, please log in again.'
            );

            okta.signOut().finally(() => {
              clearOktaSessionInfo();
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    signOut: () => {
      okta.signOut().finally(() => {
        clearOktaSessionInfo();
        window.location.reload();
      });
    },

    changePassword: ({ newPassword, oldPassword }) =>
      dispatch(changePasswordAction({ newPassword, oldPassword })),

    setLastLogin: () => {
      const date = new Date();

      return api.postLoginNotifyBackend().then((user) => {
        collectionMethods.update(user.data.id, {
          ...user.data, // have to pass in the whole object every time
          lastLogin: date.toISOString(),
        });
      });
    },

    setNewFilesCount: () => {
      api.getNewFilesCount(currentUser.data.oktaUserId).then((data) => {
        dispatch(setNewFilesCountSucceeded({ count: data.data }));
      });
    },

    ...currentUser,
  };
};
