import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { withRouter } from 'react-router';

import { Formik, Form } from 'formik';

import EnrollLayout from 'containers/enroll/enroll-layout';

import Heading from 'components/partials/heading/heading';
import FormField from 'components/shared/form/form-field';
import SubmitButton from 'components/shared/form/submit-button';
import FlashNotification from 'components/partials/flash-notification/flash-notification';
import Loader from 'components/partials/loader/loader';

import api from 'api';

import Notification from 'components/partials/notification/notification.helper';

const EnrollAccount = ({ match, history }) => {
  const [valid, setValid] = useState(null);

  const activationKey = match.params.activationKey;
  const oktaUserId = match.params.oktaUserId;

  useEffect(() => {
    // First, validate the activation key
    api
      .validateActivationKey(activationKey)
      .then((_) => setValid(true))
      .catch((error) => {
        Notification.flash(
          'Error activating user',
          'error',
          `We couldn't find a user matching that activation key (${activationKey}). Please try again or contact support. (${error.status})`
        );
        setValid(false);
      });
    // eslint-disable-next-line
  }, [match]);

  const validation = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain an uppercase letter')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[#?!@$%^&*-]/, 'Password must contain a symbol'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Must match password')
      .required('Password confirmation is required'),
  });

  return (
    <EnrollLayout>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          api
            .activateUserAccount({
              activationKey,
              oktaUserId,
              password: values.password,
            })
            .then((resp) => {
              Notification.flash(
                'Success!',
                'success',
                `Your account has been activated. Please login to continue.`
              );

              // redirect to login after activation
              history.push('/login');
            })
            .catch((error) => {
              Notification.flash(
                'Activation error',
                'error',
                `There was an error when activating your account. Please try again or contact support. (${error.status})`
              );
            });

          setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <FlashNotification />

            {valid !== false && (
              <>
                {valid === null ? (
                  <Loader {...{ loading: true }} />
                ) : (
                  <>
                    <Heading variant="h1">Choose a password</Heading>
                    <FormField
                      name="password"
                      label="Password"
                      type="password"
                      help="At least 8 characters, with 1 number and 1 special character"
                    />
                    <FormField
                      name="passwordConfirmation"
                      type="password"
                      label="Confirm password"
                    />

                    <div className="flex justify-end">
                      <SubmitButton>Continue</SubmitButton>
                    </div>
                  </>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </EnrollLayout>
  );
};

export default withRouter(EnrollAccount);
